.privacy-container {
    max-width: 100vw;
    min-height: 100vh;
    background: var(--secondairy-ultra-light-gray);
    padding: 20px;
    color: var(--primary-black);
}

.privacy-container nav {
    margin-bottom: 30px;
}

.privacy-container nav a {
    color: var(--primary-green);
    text-decoration: none;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 5px;
}

.privacy-container nav a:hover {
    text-decoration: underline;
}

.privacy-content {
    max-width: 800px;
    margin: 0 auto;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: var(--border-shadow-min);
}

.privacy-content h1 {
    color: var(--primary-black);
    margin-bottom: 20px;
    font-size: 2rem;
}

.privacy-content h2 {
    color: var(--primary-green);
    margin: 30px 0 15px;
    font-size: 1.3rem;
}

.privacy-content p {
    margin-bottom: 15px;
    line-height: 1.6;
    color: var(--secondairy-medium-gray);
    font-weight: 400;
}

.privacy-content ul {
    margin: 15px 0;
    padding-left: 20px;
}

.privacy-content li {
    margin-bottom: 10px;
    line-height: 1.6;
    color: var(--secondairy-medium-gray);
}

.info-page-section {
    position: relative;
    height: auto;
    margin: 40px 0;
}

.info-page-section:first-of-type {
    margin-top: 20px;
}

.info-page-section:last-of-type {
    margin-bottom: 20px;
}

.info-page-section h2 {
    color: var(--primary-green);
    margin: 0 0 15px;
    font-size: 1.3rem;
    width: 100%;
    text-align: right;
}

.info-page-section p {
    margin-bottom: 15px;
    line-height: 1.6;
    color: var(--secondairy-medium-gray);
}

.info-page-section ul {
    margin: 15px 0;
    padding-left: 20px;
}

.info-page-section li {
    margin-bottom: 10px;
    line-height: 1.6;
    color: var(--secondairy-medium-gray);
}

@media only screen and (max-width: 768px) {
    .privacy-content {
        padding: 20px;
    }

    .privacy-content h1 {
        font-size: 1.5rem;
    }

    .privacy-content h2 {
        font-size: 1.1rem;
    }

    .info-page-section {
        margin: 30px 0;
    }

    .info-page-section h2 {
        font-size: 1.1rem;
    }
}