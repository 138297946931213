#langingpage--mobile {
    position: relative;
    font-family: aktiv-grotesk-thin, sans-serif;
    font-weight: 200;
    font-style: normal;
    background-color: #000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#landingpage--mobile section {
    height: 100vh;
    overflow: hidden;
    max-width: 100vw;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
}

#landingpage--mobile .button_no_fill {
    max-width: 230px;
    min-width: 80px;
    width: 190px;
    height: 40px;
    border-radius: 7.5px;
    outline: none;
    border: none;
    background-color: transparent;
    border: #fff solid 1px;
    color: white;
    font-weight: 700;
    cursor: pointer;
    font-size: 0.9rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 700;
    font-style: normal;
}

#landingpage--mobile .button_fill {
    max-width: 230px;
    min-width: 80px;
    width: 190px;
    height: 40px;
    border-radius: 7.5px;
    outline: none;
    border: none;
    background-color: var(--primary-green);
    color: white;
    font-weight: 700;
    cursor: pointer;
    font-size: 0.9rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 700;
    font-style: normal;
}

#landingpage--mobile .radial_gradient {
    position: absolute;
    z-index: 1;
    height: 120vh;
    width: 120%;
    left: -10%;
    top: -60vh;
    background: radial-gradient(circle, rgba(70, 66, 90, 1) 5%, rgba(0, 0, 0, 1) 50%);
}

#landingpage--mobile h2 {
    color: #fff;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 300;
    width: 100vw;
    text-align: center;
    margin-bottom: 40px;
}

#landingpage--mobile h3 {
    color: #fff;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 500;
    text-align: center;
    margin: 15px 0;
    font-size: 1.5rem;
}

#landingpage--mobile h4 {
    color: #fff;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 500;
    text-align: center;
    margin: 5px 0;
}

/* SECTION POPOUT WINDOW */

#landingpage--mobile .popout_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    background-color: transparent;
    backdrop-filter: blur(7.5px);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    transition: opacity 500ms ease-in-out;
    opacity: 0;
}

#landingpage--mobile .popout_container.active {
    pointer-events: all;
    opacity: 1;
}

#landingpage--mobile .popout_container .popout_box {
    position: relative;
    width: 90%;
    height: 30%;
    background-color: #232323;
    border: var(--primary-purple) solid 1px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#landingpage--mobile .popout_container .popout_box .popout_close {
    position: absolute;
    top: 7.5px;
    right: 10px;
    color: black;
    background-color: var(--primary-green);
    width: 25px;
    height: 25px;
    border-radius: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    font-weight: 700;
}

#landingpage--mobile .popout_content {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

#landingpage--mobile .popout_content h4 {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.3rem;
}

#landingpage--mobile .popout_content input {
    border: var(--primary-purple) 1px solid;
    background-color: transparent;
    height: 45px;
    width: 100%;
    border-radius: 7.5px;
    padding: 0 10px;
    color: #fff;
    font-size: 1rem;
}

#landingpage--mobile .popout_content input:focus {
    outline: var(--primary-purple) 1px solid;
    background-color: rgba(70, 66, 99, 0.25);
}

#landingpage--mobile .popout_content button {
    width: 100%;
    height: 45px;
    border-radius: 7.5px;
    border: none;
    background-color: var(--primary-green);
    font-size: 0.8rem;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* SECTION HEADER */

#landingpage--mobile header {
    position: fixed;
    height: 10vh;
    width: 95%;
    top: 0px;
    z-index: 150;
    border-bottom: rgba(70, 66, 99, 0.6) 1px solid;
    opacity: 1;
    transition: all 500ms ease-in-out;
    padding: 0 10px;
}

#landingpage--mobile header.hidden {
    opacity: 0;
    pointer-events: none;
}

#landingpage--mobile header.background {
    background-color: #000;
}

#landingpage--mobile .header_content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10vh;
    width: 100%;
}

#landingpage--mobile .header_content a {
    height: 10vh;
}

#landingpage--mobile .header_content img {
    height: 100%;
}

#landingpage--mobile .header_content .hamburger_menu {
    position: relative;
    z-index: 120;
    width: 50px;
    height: 50px;
}

/* !SECTION */

/*  SECTION MENU POPUP  */

#landingpage--mobile .menu_popup {
    position: fixed;
    left: 100vw;
    top: 0;
    z-index: 101;
    background-color: #000;
    width: 100vw;
    height: 100vh;
    transition: all 600ms ease-in-out;
}

#landingpage--mobile .menu_popup.active {
    transform: translateX(-100vw);
}

#landingpage--mobile .menu_popup .menu_popup__content {
    width: 100%;
    padding: 12.5vh 25px ;
}

#landingpage--mobile .menu_popup .menu_popup__content .menu_bar__buttons {
    display: flex;
    gap: 10px;
    height: 60px;
}

#landingpage--mobile .menu_popup .menu_popup__content .menu_bar__buttons button {
    height: 100%;
}

#landingpage--mobile .menu_popup .menu_popup__content .menu_bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0;
    padding: 0;
}

#landingpage--mobile .menu_popup .menu_popup__content .menu_bar a {
    text-decoration: none;
    list-style: none;
    padding: 10px 0;
    color: #fff;
    font-weight: 600;
    font-size: 1.75rem
}

#landingpage--mobile .menu_popup .signin_options {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10%;
    margin-top: 10%;
}

#landingpage--mobile .menu_popup .signin_options .signin_option {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    border: var(--primary-green) 2px solid;
}

#landingpage--mobile .menu_popup .signin_options .signin_option {
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 500ms ease-in-out;
}

#landingpage--mobile .menu_popup .signin_options .signin_option h4 {
    font-size: 1.5rem;
    line-height: 2rem;
}

/* !SECTION */

/* SECTION MAIN PAGE */

#landingpage--mobile .landing_page__page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    padding-top: 15vh;
    padding-bottom: 60px;
    height: auto;
    justify-content: space-between;
}

#landingpage--mobile .landing_page__page .radial_gradient {
    position: absolute;
    height: 120vh;
    width: 120%;
    left: -10%;
    top: -60vh;
    background: radial-gradient(circle, rgba(70, 66, 90, 1) 5%, rgba(0, 0, 0, 1) 40%);
}

#landingpage--mobile .landing_page__page h1 {
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 800;
    font-style: normal;
    z-index: 51;
    color: var(--primary-green);
    font-weight: 800;
    font-size: 3.2rem;
    line-height: 3.5rem;
    text-align: center;
    margin: 0;
    word-spacing: 10px;
    width: 100%;
}

#landingpage--mobile .landing_page__page p {
    text-align: center;
    line-height: 20px;
    margin: 30px;
}

#landingpage--mobile .landing_page__page p:nth-of-type(2) {
    text-align: center;
    line-height: 20px;
    margin: 15px;
}

#landingpage--mobile .landing_page__page .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    z-index: 51;
    margin: 20px 0;
}

#landingpage--mobile .landing_page__page .buttons a {
    height: 60px;
}

#landingpage--mobile .landing_page__page .brands {
    display: grid;
    grid-template: auto / 1fr 1fr;
    width: 80%;
    column-gap: 40px;
    row-gap: 20px;
    opacity: 0.8;
    margin: 15px;
}

#landingpage--mobile .landing_page__page .brands a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
}

#landingpage--mobile .landing_page__page .brands #opius {
    height: 70%;
}

#landingpage--mobile .landing_page__page .brands #heyu {
    height: 70%;
}

#landingpage--mobile .landing_page__page .brands #pomton {
    height: 70%;
}

#landingpage--mobile .landing_page__page .brands img {
    width: 80%;
    height: auto;
}

a.button_fill,
a.button_no_fill {
    height: 6vh;
    width: 15vw;
}

/* !SECTION */

/* SECTION PROBLEMS */

#landingpage--mobile #problems {
    align-items: center;
    padding-bottom: 60px;
    height: auto;
}

#landingpage--mobile .problems {
    display: grid;
    grid-template: 1fr 1fr / 1fr;
    height: 100%;
    width: 85vw;
    gap: 4vw;
}

#landingpage--mobile .problem__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#landingpage--mobile .problem__container svg {
    height: 25%;
    width: 25%;
}

#landingpage--mobile .problem__container p {
    line-height: 1.1rem;
    text-align: center;
    margin: 0;
}

/* !SECTION */

/* SECTION SOLUTION PAGE */

#landingpage--mobile .our_solution__page {
    align-items: center;
    padding-bottom: 15px;
    height: auto;
    padding-bottom: 60px;
}

#landingpage--mobile .solution__container {
    position: relative;
    overflow: hidden;
    height: auto;
    width: 95vw;
    border-radius: 15px;
    border: 1px solid rgba(70, 66, 90, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

#landingpage--mobile .solution__container .swipe {
    position: relative;
    z-index: 51;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.6rem;
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 5px;
}

#landingpage--mobile .solution__container .swipe svg {
    font-size: 1rem;
}

#landingpage--mobile .solution__container h3 {
    position: relative;
    z-index: 51;
}

#landingpage--mobile .solution__container .solution_topics {
    position: relative;
    z-index: 51;
    width: 100%;
    height: auto;
    gap: 30px;
    margin-bottom: 10px;
    padding: 0;
    overflow-y: hidden;
}

#landingpage--mobile .solution__container .solution_topics .topic {
    position: relative;
    padding: 0;
    cursor: pointer;
    border-radius: 10px;
    transition: all 400ms ease-in-out;
    width: calc(95vw - 22.5px);
}

#landingpage--mobile .solution__container .solution_topics .topic.selected {
    border: var(--primary-green) solid 1px;
    background-color: rgba(121, 199, 188, 0.15);
}

#landingpage--mobile .solution__container .solution_topics .topic p {
    line-height: 1.1rem;
}

#landingpage--mobile .solution__container .solution_topics .title {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 15px;
}

#landingpage--mobile .solution__container .solution_topics .title svg {
    height: 25px;
    width: 25px;
}

#landingpage--mobile .solution__container .screenshot {
    position: relative;
    z-index: 51;
    width: calc(100%);
    height: auto;
    border-radius: 10px;
}

#landingpage--mobile .solution__container .content {
    position: relative;
    z-index: 51;
    border-radius: 10px;
    display: grid;
    grid-template: 70px 70px / 1fr 1fr;
    row-gap: 10px;
    gap: 10px;
}

#landingpage--mobile .solution__container .content img {
    height: auto;
    height: 70px;
    border-radius: 10px;
    justify-self: center;
}

#landingpage--mobile .solution__container .content img:nth-child(1) {
    grid-column: span 2;
}

#landingpage--mobile #ordering-app .solution__container .solution_topics {
    display: flex;
    flex-direction: column;
}

#landingpage--mobile #ordering-app .solution__container .content {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0;
    height: calc(100% - 85px);
}

#landingpage--mobile #ordering-app .content .content_image {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#landingpage--mobile #ordering-app .content img {
    height: 100%;
}

.swiper-slide {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

/* !SECTION */

/*  SECTION TESTIMONIALS */

#landingpage--mobile .testimonials {
    display: flex;
    flex-direction: column;
    align-items: top;
    justify-content: center;
    width: 80%;
    height: calc(100% - 200px);
    padding: 30px 0;
}

#landingpage--mobile .testimonials .testimonial {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 30px 15px;
}

#landingpage--mobile .testimonials .testimonial p {
    line-height: 1.1rem;
}

#landingpage--mobile .testimonials .testimonial_stars {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 25px;
    width: 100%;
    grid-column: span 2;
}

#landingpage--mobile .testimonials .testimonial_stars img {
    height: 100%;
}

#landingpage--mobile .testimonials .testimonial_photo_and_name {
    display: flex;
    gap: 15px;
}

#landingpage--mobile .testimonials .testimonial_photo_and_name img {
    width: 33%;
    border-radius: 50%;
}

#landingpage--mobile .testimonials .testimonial_photo_and_name h4 {
    line-height: 1.2rem;
    text-align: left;
}

#landingpage--mobile .testimonials .testimonial_photo_and_name .name_tags p {
    font-weight: 300;
}

/*  !SECTION  */

/*  SECTION PRICING  */

#landingpage--mobile .pricing__page {
    height: auto;
    width: 95%;
}

#landingpage--mobile .pricing_container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#landingpage--mobile .period_box_container {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

#landingpage--mobile .period_box_container .period_box {
    display: flex;
    padding: 5px;
    border: var(--primary-purple) solid 1px;
    border-radius: 15px;
    width: 5%;
    max-width: 250px;
    min-width: 220px;
    height: 100%;
}

#landingpage--mobile .period_box_container .period_box>p {
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

#landingpage--mobile .period_box_container .period_box>p.selected {
    background-color: var(--primary-purple);
}

#landingpage--mobile .period_box_container .period_box>p:nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

#landingpage--mobile .period_box_container .period_box>p:nth-child(2) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

#landingpage--mobile .period_box_container .pricing_types {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
    height: 50px;
}

#landingpage--mobile .period_box_container .pricing_types > p {
    font-size: 0.9rem;
    opacity: 0.5;
    transition: all 250ms ease-in-out;
}

#landingpage--mobile .period_box_container .pricing_types > p.selected {
    opacity: 1;
    text-decoration: underline;
}

#landingpage--mobile .price_options_container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 95%;
    height: auto;
    padding-top: 30px;
}

#landingpage--mobile .price_option {
    position: relative;
    border: var(--primary-purple) 1px solid;
    height: 60vh;
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
    padding: 20px;
}

#landingpage--mobile .price_option:nth-child(2) {
    border-color: yellow;
}

#landingpage--mobile .price_option .best_value {
    position: absolute;
    top: 0;
    right: 15px;
    background-color: yellow;
    padding: 2.5% 10px;
    z-index: 51;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

#landingpage--mobile .price_option .best_value p {
    font-weight: 800;
    color: #000;
    margin: 0;
    font-size: 0.8rem;
}

#landingpage--mobile .price_option .title {
    position: relative;
    z-index: 51;
    text-transform: uppercase;
    color: #fff;
    font-weight: 200;
    font-size: 1.2rem;
}

#landingpage--mobile .price_option .price_wrapper {
    position: relative;
    z-index: 51;
    color: #fff;
    width: 100%;
    margin: 35px 0;
}

#landingpage--mobile .price_option .price_wrapper .price {
    display: flex;
    gap: 2.5px;
    align-items: center;
    font-weight: 800;
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
    height: 30px;
}

#landingpage--mobile .price_option .price_wrapper .price .period {
    font-size: 0.75rem;
    font-weight: 200;
    letter-spacing: 0.1rem;
    padding-top: 12.5px;
}

#landingpage--mobile .price_option .price_wrapper .billing_info {
    font-size: 0.75rem;
    font-weight: 200;
    opacity: 0.5;
    letter-spacing: 0.1rem;
}

#landingpage--mobile .price_option .features {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    z-index: 51;
}

#landingpage--mobile .price_option .features .feature {
    display: flex;
    align-items: center;
    gap: 10px;
}

#landingpage--mobile .price_option .features .feature svg {
    max-width: 25px;
    max-height: 25px;
}

#landingpage--mobile .price_option .features .feature .feature--description {
    display: flex;
    align-items: center;
    gap: 7.5px;
    margin: 0;
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.3rem;
    color: #fff;
}

#landingpage--mobile .price_option .features .feature .feature--description > p {
    font-size: 0.75rem !important;
    font-weight: 200;
    letter-spacing: 0.05rem;
    margin: 0;
}

#landingpage--mobile .price_option button {
    position: absolute;
    left: 50%;
    bottom: 5%;
    transform: translateX(-50%);
    z-index: 51;
    height: 15%;
    width: 90%;
    margin-top: 25px;
    border-radius: 15px;
    border: none;
    outline: none;
    background-color: var(--primary-green);
    font-size: 1.15rem;
    font-weight: 700;
    cursor: pointer;
    color: #000;
}

/*  !SECTION  */

/*  SECTION CONTACT FOOTER  */

#landingpage--mobile footer {
    display: grid;
    grid-template: auto / 1fr 1fr;
    gap: 15px;
    position: relative;
    height: 25vh;
    width: 90vw;
    padding: 5px;
    color: #fff;
}

#landingpage--mobile footer img {
    height: 100px;
    grid-column: span 2;
    justify-self: center;
}

#landingpage--mobile footer p {
    margin: 0;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.2rem;
}

#landingpage--mobile footer a {
    text-decoration: none;
}

/*  !SECTION  */